// Forms
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// Group
// Label
// Input
// Errors
// Plaintext
// Icons

// Group
.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.25rem;
}

// Label
.form-label {
	color: $gray-700;
	margin-bottom: 0.5rem;

	@include letter-spacing(0.7, 0.875rem);
	@include font-roboto($font-size: 0.875rem);
}

label.form-label {
	cursor: pointer;
}

// Input
.form-input {
	appearance: none;
	border: 1px solid $gray-300;
	border-radius: 0.25rem;
	color: $gray-800;
	letter-spacing: 0.009375em;
	padding: 0.75rem 1rem;
	width: 100%;

	&:focus {
		border: 1px solid $gray-400;
		box-shadow: $box-shadow;
		outline: none;
	}

	@include font-roboto;

	&.has-trailing-icon {
		padding-right: 1.5rem;
	}
}

// Plaintext

.form-plaintext {
	color: $gray-700;
	padding: 0.75rem 1rem;

	@include font-roboto;
}

// Errors

.form-error {
	color: $red-500;
	display: flex;
	margin-top: 0.5rem;

	@include font-roboto($font-size: 0.75rem, $font-weight: 500);
	@include letter-spacing(0.4, 0.75rem);
}

// Icons

.trailing-icon {
	bottom: 0.75rem;
	cursor: default;
	font-size: 1.25rem;
	line-height: 1.5rem;
	pointer-events: none;
	position: absolute;
	position: absolute;
	right: 0.5rem;
}
