// Calls View
//
// @author Erik Galloway <egalloway@claruscare.com>
//
// Action bar
// View
// Column
// Call info block

// Action bar

.call-action-bar {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	display: flex;
	flex-shrink: 0;
	height: 3.5rem;
	justify-content: space-between;
	margin: 1rem;
	overflow-x: auto;
}

.call-actions {
	align-items: center;
	display: flex;
}

.call-action {
	align-items: center;
	background: $white;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	color: $purple;
	display: inline-flex;
	font-size: 1.1rem;
	height: 100%;
	justify-content: center;
	padding: 0 1rem;
	text-transform: uppercase;
	transition: all 150ms $animation-standard-timing;
	white-space: nowrap;

	@include letter-spacing(1.5, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);

	.call-actions:first-child &:first-child,
	.call-actions:first-child &:first-child:hover,
	.call-actions:first-child &:first-child:focus {
		border-left: 0;
	}

	.call-actions:not(:first-child) > &:last-child,
	.call-actions:not(:first-child) > &:last-child:hover,
	.call-actions:not(:first-child) > &:last-child:focus {
		border-right: 0;
	}

	&:focus {
		background: rgba($purple, 0.08);
		border-color: $gray-300;
		color: $purple;
		outline: 0;
	}

	&:hover {
		background: rgba($purple, 0.04);
		border-color: $gray-300;
		color: $purple;
		text-decoration: none;
	}
}

.call-action-icon {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	margin-right: 0.5rem;
}

// View
.calls-show > .call {
	display: flex;
	flex-direction: column;
	margin-top: $navbar-height;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;

	.call-container {
		display: flex;
		flex-direction: column;
		min-height: fit-content;
		width: 100%;
	}

	.call-columns {
		display: flex;
		flex-direction: column;

		.call-message {
			flex-direction: column-reverse;
		}

		@include media-breakpoint-up(lg) {
			flex-flow: row nowrap;
		}
	}
}

// Column

.call-column {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100%;

	&.call-info {
		padding-left: 1rem;
		padding-right: 1rem;

		@include media-breakpoint-only(md) {
			order: 1;
		}

		@include media-breakpoint-up(lg) {
			height: 100%;
			padding-right: 0.5rem;
			width: percentage(5 * 0.125);
		}
	}

	&.patient-info {
		padding-left: 1rem;
		padding-right: 1rem;

		@include media-breakpoint-only(md) {
			order: 2;
		}

		@include media-breakpoint-up(lg) {
			height: 100%;
			padding-left: 0.5rem;
			width: percentage(3 * 0.125);
		}
	}
}

// Call info block

.call-info-block {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 1rem;
	width: 100%;

	&:last-child {
		margin-bottom: 1rem;
	}
}

.call-info-block-title {
	background: $white;
	border: 1px solid $gray-200;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	padding: 0.5rem 1rem;
	position: relative;
	text-align: left;
	width: 100%;

	@include font-nunito($font-size: 1.25rem, $font-weight: 500);
}

.call-info-title-caret {
	align-items: center;
	bottom: 0;
	color: $gray-700;
	display: flex;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	position: none;
	position: absolute;
	right: 0;
	top: 0;
}

.call-info-card {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	box-shadow: $box-shadow-md;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	padding: 1rem;
	width: 100%;

	@include font-roboto();
}
