// Dashboard
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View
// Title
// Filter
// Container
// Chart
// Card row
// Card
// Loader
// SVG Export
// Date Range Filter

// View

.dashboard {
	display: flex;
	flex-direction: column;
	overflow: scroll;
	padding: 1rem;
	width: 100%;
}

// Title

.dashboard-title {
	margin-bottom: 0.25rem;
	margin-top: $navbar-height;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);

	@include media-breakpoint-up(md) {
		margin-bottom: 1rem;
	}
}

.dashboard-header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
}

// Container

.dashboard-insights {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.dashboard-container {
	display: flex;
	min-height: 500px;
	width: 100%;
}

// Chart

.dashboard-chart {
	background-color: $violet-900;
	border-radius: 0.25rem;
	display: flex;
	flex-direction: column;
	min-height: 400px;
	width: 100%;
}

.dashboard-chart-header {
	display: flex;
}

.dashboard-chart-header-container {
	margin-bottom: 1rem;
	padding: 1rem;
	width: 50%;
}

.dashboard-chart-title {
	color: $orange-300;
	margin-bottom: 2rem;

	@include letter-spacing(-0.5, 1.4rem);
	@include font-roboto($font-size: 1.4rem, $font-weight: 500);
}
.insight-chart-subtitle1 {
	align-items: center;
	color: $gray-200;
	display: flex;
	font-family: Roboto, monospace;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 10px;
	margin-left: 5px;

	@include letter-spacing(1.15, 0.75rem);
	&::before {
		background-color: #f1c188;
		border-radius: 7.5px;
		border-radius: 7.5px;
		border-radius: 10.5px;
		content: '';
		display: inline-block;
		height: 22px;
		margin-right: 10px;
		width: 22px;
	}
}
.insight-chart-subtitle2 {
	align-items: center;
	color: $gray-200;
	display: flex;
	font-family: Roboto, monospace;
	font-size: 14px;
	font-weight: 400;
	margin-left: 5px;

	@include letter-spacing(1.15, 0.75rem);
	&::before {
		background-color: #ba9bf2;
		border-radius: 7.5px;
		border-radius: 7.5px;
		border-radius: 10.5px;
		content: '';
		display: inline-block;
		height: 22px;
		margin-right: 10px;
		width: 22px;
	}
}

.dashboard-chart-subtitle {
	color: $gray-200;

	@include letter-spacing(1.25, 0.75rem);
	@include font-roboto($font-size: 0.75rem, $font-weight: 500);
}

.dashboard-chart-response-times {
	display: none;

	@include media-breakpoint-up(sm) {
		display: flex;
		flex-direction: column;
	}
}
.dashboard-response-time-container {
	display: flex;
	justify-content: space-between;
}

.dashboard-response-time-title {
	align-items: center;
	color: $gray-500;
	display: flex;
	flex-flow: row nowrap;
	text-transform: uppercase;

	@include letter-spacing(1.5, 0.8125rem);
	@include font-roboto($font-size: 0.8125rem, $font-weight: 500);
}

.dashboard-response-time-data {
	align-items: center;
	color: $gray-200;
	display: flex;
	flex-flow: row nowrap;

	@include letter-spacing(0.5, 1.5rem);
	@include font-roboto($font-size: 1.5rem, $font-weight: 500);
}

.dashboard-response-time-icon {
	display: none;
	font-size: 0.9375rem;
	margin-right: 0.5rem;

	@include media-breakpoint-up(md) {
		display: flex;
	}
}

// Card row

.dashboard-card-row {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include media-breakpoint-up(lg) {
		flex-flow: row nowrap;
	}
}

.dashboard-card-row > .dashboard-card {
	min-height: 350px;
	width: 100%;

	> :first-child {
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		overflow: hidden;
		width: 50%;
	}

	@include media-breakpoint-up(lg) {
		&:first-child {
			margin-right: 0.5rem;
		}

		&:last-child:not(:only-child) {
			margin-left: 0.5rem;
		}
	}
}

// Card

.dashboard-card {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	display: flex;
	margin-bottom: 1rem;
	min-height: 500px;
	padding: 1rem;
	width: 100%;
}

.dashboard-card-flush {
	padding: 0;
}

// Loader
.dashboard-card-loader {
	align-items: center;
	color: $orange;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 100%;
}

.dashboard-card-loader-text {
	color: $gray-800;
	margin-top: 1rem;
	text-transform: uppercase;

	@include letter-spacing(1.25, 0.875rem);
	@include font-roboto($font-size: 0.875rem, $font-weight: 500);
}

// SVG Export

.apexcharts-menu-item.exportSVG {
	display: none;
}

// Date Range Filter

.dashboard-filter {
	align-items: center;
	border-radius: 0.25rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 0.25rem;

	@include media-breakpoint-up(md) {
		margin-bottom: 1rem;
		margin-top: $navbar-height;
		min-width: 350px;
		padding: 1rem;
	}
}

.dashboard-filter-input {
	appearance: none;
	border: 1px solid $gray-300;
	border-radius: 0.25rem 0 0 0.25rem;
	border-right-width: 0;
	color: $gray-800;
	cursor: pointer;
	letter-spacing: 0.009375em;
	padding: 0.75rem 1rem 0.75rem 3rem;
	width: 100%;
	&.calls-page-date-filter {
		border-right-width: 1px;
		font-size: 0.875rem;
		padding: 0.75rem 0.3rem 0.75rem 2.5rem;
	}

	&:focus {
		border: 1px solid $gray-400;
		box-shadow: $box-shadow;
		outline: none;
	}

	@include font-roboto;

	&::placeholder {
		color: $gray-700;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;

		@include letter-spacing(0.5, 0.875rem);
	}
}

.dashboard-filter-input-icon {
	align-items: center;
	color: $purple;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	max-height: 3rem;
	padding: 0.5rem;
	position: absolute;
	top: 0;
	width: 3rem;
	&.calls-page-date-filter-icon {
		width: 2rem;
	}
}

.dashboard-filter-btn {
	background: $white !important;
	border: 1px solid $gray-300;
	border-radius: 0 0.25rem 0.25rem 0;
	height: 100%;
	min-width: 2rem;
	padding: 0 1rem;
	&.calls-page-date-filter-btn {
		min-width: 1rem;
	}

	.btn-ripple {
		border-radius: 0 0.25rem 0.25rem 0;
	}
}
