// Profile
//
// @author Alejandro Sanchez <asanchez@claruscare.com>
//
// View
// Container
// Profile section
// Address section
// Address title
// Address icon
// Form

// View
.profile-show {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 1rem;
	width: 100%;
}

.profile-name-title {
	margin-bottom: 1rem;
	margin-top: $navbar-height;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);
}

.profile-header-btn {
	align-items: center;
	border-radius: 50%;
	color: $purple;
	display: flex;
	flex-direction: column;
	height: 50px;
	justify-content: center;
	margin-bottom: 1rem;
	margin-top: $navbar-height;
	padding: 1rem;
	transition: all 0.4s;
	width: 50px;

	@include font-roboto($font-size: 1.5rem);

	@include hover-focus {
		outline: 0;
		text-decoration: none;
	}

	&:hover {
		background: rgba($purple, 0.08);
		color: $gray-800;
	}
}

// Container
.profile-container {
	display: flex;
	flex-direction: column;
	width: 100%;

	@include media-breakpoint-up(lg) {
		flex-flow: row nowrap;
	}
}

// Profile section
.profile-section {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-right: 0.5rem;
		overflow: hidden scroll;
		width: 50%;
	}
}

// Address section
.address-section {
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-left: 0.5rem;
		width: 50%;
	}
}

// Address title
.address-name-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding: 1rem;

	@include font-roboto($font-size: 1.5rem);
}

// Address icon
.address-icon {
	color: $purple;
}

// Form
.profile-form {
	background: $white;
	border: 1px solid $gray-400;
	border-radius: 0.25rem;
	margin-bottom: 1rem;
	padding: 1rem;
}
